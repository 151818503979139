import React, { useEffect } from "react"
import { Link } from "gatsby"
import './styles.scss'
import CustomCarousel from "../../../../components/CustomCarousel/CustomCarousel"
import image0 from '../../../../images/studio-visit/00.jpg'
import image1 from '../../../../images/studio-visit/01.jpg'
import image2 from '../../../../images/studio-visit/02.jpg'
import image3 from '../../../../images/studio-visit/03.jpg'
import image4 from '../../../../images/studio-visit/04.jpg'
import image5 from '../../../../images/studio-visit/05.jpg'
import image6 from '../../../../images/studio-visit/06.jpg'
import image7 from '../../../../images/studio-visit/07.jpg'
import image8 from '../../../../images/studio-visit/08.jpg'
import image9 from '../../../../images/studio-visit/09.jpg'
import image10 from '../../../../images/studio-visit/10.jpg'
import image11 from '../../../../images/studio-visit/11.jpg'
import image12 from '../../../../images/studio-visit/12.jpg'
import image13 from '../../../../images/studio-visit/13.jpg'
import image14 from '../../../../images/studio-visit/14.jpg'
import image15 from '../../../../images/studio-visit/15.jpg'
import image16 from '../../../../images/studio-visit/16.jpg'
import image17 from '../../../../images/studio-visit/17.jpg'
import image18 from '../../../../images/studio-visit/18.jpg'
import image19 from '../../../../images/studio-visit/19.jpg'
import image20 from '../../../../images/studio-visit/20.jpg'
import image21 from '../../../../images/studio-visit/21.jpg'
import image22 from '../../../../images/studio-visit/22.jpg'
import image23 from '../../../../images/studio-visit/23.jpg'
import image24 from '../../../../images/studio-visit/24.jpg'
import image25 from '../../../../images/studio-visit/25.jpg'
import image26 from '../../../../images/studio-visit/26.jpg'
import image27 from '../../../../images/studio-visit/27.jpg'
import image28 from '../../../../images/studio-visit/28.jpg'
import image29 from '../../../../images/studio-visit/29.jpg'
import image30 from '../../../../images/studio-visit/30.jpg'
import image31 from '../../../../images/studio-visit/31.jpg'
import image32 from '../../../../images/studio-visit/32.jpg'
import image33 from '../../../../images/studio-visit/33.jpg'
import image34 from '../../../../images/studio-visit/34.jpg'
import image35 from '../../../../images/studio-visit/35.jpg'
import image36 from '../../../../images/studio-visit/36.jpg'
import image37 from '../../../../images/studio-visit/37.jpg'
import image38 from '../../../../images/studio-visit/38.jpg'

let images = [image0, image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15, image16, image17, image18, image19, image20, image21, image22, image23, image24, image25, image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, image36, image37, image38]

const StudioVisit = () => {

  return (
    <div className="studio-visit">
      <Link to="/c-speakeasy/kosuke-kawahara?pastspeakeasy=true" className="exit">EXIT</Link>
      <div className="carousel">
        <CustomCarousel
          customArrows={true}
          directImages={images}
          showImageTitle='true'
        />
      </div>
    </div>
  )
}

export default StudioVisit
